import request from '@/auth/jwt/useJwt'

// 获取个人信息
export function detail() {
  return request.axiosIns({
    url: 'user/detail',
    method: 'get',
  })
}

// 更新个人信息
export function submit(data) {
  return request.axiosIns({
    url: 'user/detail',
    method: 'PUT',
    data,
  })
}
