<template>
  <div>
    <b-card>
      <b-tabs
        vertical
        active-nav-item-class="bg-light-primary nav-vertical-w justify-content-start x-anim-fade-in"
        nav-wrapper-class="nav-vertical">
        <b-tab
          active
          title="基本信息">
          <b-card-title>
            <div class="x-text-bold ml-2 mt-1">基本信息</div>
          </b-card-title>
          <b-card-body class="p-0">
            <x-form-validator ref="refFormObserver">
              <x-form-feild label="头像">
                <div style="position: relative;width: 100px;height: 100px"  @click="handlePictureCardPreview">
                  <b-avatar
                    :class="formData.avatar ? 'el-upload-list__item-thumbnail upload-cx' : 'el-upload-list__item-thumbnail'"
                    style="width: 100px;height: 100px;"
                    size="lg"
                    :src="formData.avatar" alt=""
                    text="avatar"
                    variant="light-primary"
                  >
                  </b-avatar>
                </div>
                <el-upload
                  :action="action"
                  :headers="headers"
                  :multiple="false"
                  :file-list="fileList"
                  accept=".jpg, .png, .gif"
                  :show-file-list="false"
                  :on-exceed="onExceed"
                  :on-success="handleSuccess">
                  <b-button  variant="primary" size="sm" v-ripple.400="$x.ripple.primary" class="mt-1 ml-2" style="margin-left: 10px">上传</b-button>
                </el-upload>
                <b-modal
                  title-class="x-text-bold"
                  body-class="pl-4 pr-4"
                  cancel-variant="flat-secondary"
                  ok-title="删除"
                  cancel-title="取消"
                  centered
                  title="预览"
                  :visible.sync="dialogVisible"
                  @hidden="restModal"
                  @ok="handleRemove">
                  <img width="100%" :src="dialogImageUrl" alt="">
                </b-modal>
              </x-form-feild>
              <x-form-feild label="姓名">
                <b-form-input
                  v-model="formData.name"
                  placeholder="请输入姓名"
                />
              </x-form-feild>
              <x-form-feild label="邮箱" email>
                <b-form-input
                  v-model="formData.email"
                  placeholder="请输入邮箱"
                />
              </x-form-feild>
              <x-form-feild label="联系电话">
                <b-form-input
                  v-model="formData.telephone"
                  placeholder="请输入联系电话"
                />
              </x-form-feild>
              <x-form-feild label="描述">
                <b-form-textarea
                  v-model="formData.description"
                  placeholder="请输入描述"
                />
              </x-form-feild>
              <b-button class="ml-1 full-width" variant="primary" v-ripple.400="$x.ripple.primary" @click="enter">更新</b-button>
            </x-form-validator>
          </b-card-body>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { BTabs, BTab, BCard, BCardTitle, BCardBody, BFormInput, BFormTextarea, BButton, BModal, BAvatar } from 'bootstrap-vue'
import XFormValidator from '@/@core/components/cx/table/XFormValidator.vue'
import XFormFeild from '@/@core/components/cx/table/XFormFeild.vue'
import { detail, submit } from '@/api/system/usercenter/basesettings'
import { getToken } from '@/@core/auth/token'

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    BCardTitle,
    BCardBody,
    XFormValidator,
    XFormFeild,
    BFormInput,
    BFormTextarea,
    BButton,
    BModal,
    BAvatar,
  },
  data() {
    return {
      formData: {},
      // 上传请求地址
      action: this.$xapi.UPLOAD,
      // 上传请求头部信息
      headers: {
        'X-Access-Token': getToken(),
      },
      dialogVisible: false,
      dialogImageUrl: '',
      fileList: [],
    }
  },
  watch: {
  },
  created() {
    this.detail()
  },
  methods: {
    detail() {
      detail().then(resp => {
        this.formData = resp.data.result
        if (this.formData.avatar) {
          this.fileList.push({
            name: '头像',
            url: this.formData.avatar,
          })
        }
      })
    },
    // 上传图片
    uploadSuccess(response) {
      this.$xtoast.success('上传成功')
      if (response.code === 'success') {
        this.formData.avatar = response.result
      }
    },
    enter() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          submit(this.formData).then(() => {
            this.$xtoast.success('更新成功')
          })
        }
      })
    },
    handleRemove() {
      this.fileList = []
      this.formData.avatar = ''
    },
    handlePictureCardPreview() {
      if (this.formData.avatar) {
        this.dialogImageUrl = this.formData.avatar
        // this.dialogImageUrl = file.url
        this.dialogVisible = true
      }
    },
    handleSuccess(response) {
      this.formData.avatar = response.result
    },
    restModal() {
      this.dialogVisible = false
    },
    onExceed() {
      this.$xtoast.warning('头像仅可上传一张，请删除后重新上传！')
    },
  },
}
</script>

<style lang="scss">
  .el-upload--picture-card {
    border: none !important;
    text-align: left;
    padding: 0px !important;
    margin: 0px !important;
    line-height: 30px !important;
  }
  .upload-cx:hover:after{
    position:absolute;
    display: block;
    width:100%;
    height: 100%;
    border-radius: 50%;
   // background-color: rgba(0, 0, 0, 0.5);
    background: rgba(0, 0, 0, 0.5) url("../utils/browse.png") no-repeat center center;
    background-size: 30px;
    content: attr(data-text);
    transition:all 3s ease;
    color: #FFF;
    text-align: center !important;
    vertical-align: middle !important;
    cursor: pointer;
  }
</style>
